
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import { withGlobalDefaultStaticProps } from '../lib/next-hooks/withGlobalDefaultStaticProps';
import { get404ErrorPageSeoConfig } from '../lib/seo/pageSeoConfigs';
import ErrorPageRenderer from '../renderers/ErrorPageRenderer';
import { NextPageWithLayout } from '../types/global-types';
type Page404StaticProps = {};
const getStaticProps: GetStaticProps = async (context) => {
    return withGlobalDefaultStaticProps(context, { props: {} });
};
const Page404: NextPageWithLayout<Page404StaticProps> = (props) => {
    const { title, description } = get404ErrorPageSeoConfig();
    return (<>
      <NextSeo noindex title={title} description={description}/>
      <ErrorPageRenderer title={title} description={description}/>
    </>);
};
Page404.displayName = 'Page404';
export default Page404;

    async function __Next_Translate__getStaticProps__1923ae1b891__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1923ae1b891__ as getStaticProps }
  